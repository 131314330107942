import { PageInfo } from '@ha/contentful';

import { RouteShape } from '../types';

export const transformHermesInfo = (info: PageInfo): RouteShape => {
  return {
    pathname: info.path,
    pageName: info.type,
    isRouteExists: true,
    props: {
      hasData: info.hasData,
      canonical: info.meta.canonical,
      alternatehrefs: info.meta.alternatehrefs,
    },
  };
};
