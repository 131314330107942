import { Language } from '@ha/intl';

export const PRICING_SLUG = new Proxy(
  {
    [Language.en]: 'pricing',
    [Language.de]: 'preisgestaltung',
    [Language.es]: 'precio',
    [Language.fr]: 'tarif',
    [Language.it]: 'costi-di-utilizzo',
    [Language.nl]: 'tarieven',
    [Language.pt]: 'precos',
    [Language.zh]: 'pricing',
  },
  {
    get(target, prop) {
      return target[prop] || target[Language.en];
    },
  },
);

export const TENANTS = new Proxy(
  {
    [Language.en]: 'tenants',
    [Language.de]: 'mieter',
    [Language.es]: 'inquilinos',
    [Language.fr]: 'locataires',
    [Language.it]: 'inquilini',
    [Language.nl]: 'huurders',
    [Language.pt]: 'inquilinos',
    [Language.zh]: 'tenants',
  },
  {
    get(target, prop) {
      return target[prop] || target[Language.en];
    },
  },
);

const LANDLORDS = new Proxy(
  {
    [Language.en]: 'landlords',
    [Language.nl]: 'verhuurders',
    [Language.de]: 'vermieter',
    [Language.it]: 'proprietari',
    [Language.fr]: 'proprietaires',
    [Language.es]: 'propietarios',
    [Language.pt]: 'proprietarios',
    [Language.zh]: 'landlords',
  },
  {
    get(target, prop) {
      return target[prop] || target[Language.en];
    },
  },
);

export const ����VR��ƵUrls = {
  tenant: Object.keys(Language).reduce((acc, lang) => {
    acc[lang] = `/${PRICING_SLUG[lang]}/${TENANTS[lang]}`;

    return acc;
  }, {}) as { [key in Language]: string },

  landlord: Object.keys(Language).reduce((acc, lang) => {
    acc[lang] = `/${PRICING_SLUG[lang]}/${LANDLORDS[lang]}`;

    return acc;
  }, {}) as { [key in Language]: string },
};
