import { Location } from 'react-router-dom';

import UrlPattern from 'url-pattern';

import { Language } from '@ha/intl';

import { getPrivateLandlordPageRoutes } from 'ha/constants/PrivateLandlordLandingPageSlug';

import {
  getAlternatehrefs,
  getAlternateLanghref,
  hasAlternateLanghref,
} from 'ha/modules/Hermes/selectors';
import { GlobalState } from 'ha/types/store';

import { STATE_NAME } from './constants';
import * as reducerSelectors from './reducer';

const getLanguageSwitcherState = (state: GlobalState) => state[STATE_NAME];

export const getCurrentLanguageCode = (state: GlobalState) =>
  reducerSelectors.getCurrentLanguageCode(getLanguageSwitcherState(state));

export const getAvailableLanguages = (state: GlobalState) =>
  reducerSelectors.getAvailableLanguages(getLanguageSwitcherState(state));

export const isLanguageAvailable = (state: GlobalState, lang: Language) =>
  reducerSelectors.isLanguageAvailable(getLanguageSwitcherState(state), lang);

export const getLocalisedLocations = (state: GlobalState) =>
  getLanguageSwitcherState(state).localisedLocations;

const getLocalisedLocationWithoutDefault = (
  state: GlobalState,
  lang: Language,
  currentLocation: Location,
) => {
  if (!isLanguageAvailable(state, lang)) {
    return undefined;
  }

  // let's try to find the url in the localised locations.
  const localisedLocation = reducerSelectors.getLocalisedLocation(
    getLanguageSwitcherState(state),
    lang,
  );

  if (localisedLocation) {
    return `${localisedLocation}${currentLocation.search}`;
  }

  // let's check if the language is available in Hermes
  if (hasAlternateLanghref(state, lang, currentLocation.pathname)) {
    return `${getAlternateLanghref(state, lang)}${currentLocation.search}`;
  }

  return undefined;
};

export const getLocalisedLocation = (
  state: GlobalState,
  lang: Language,
  currentLocation: Location,
) => {
  if (!isLanguageAvailable(state, lang)) {
    return `${currentLocation.pathname}${currentLocation.search}`;
  }

  const localisedLocation = getLocalisedLocationWithoutDefault(
    state,
    lang,
    currentLocation,
  );

  if (localisedLocation) {
    return localisedLocation;
  }

  const langForUrl = lang === 'en' ? '' : `/${lang}`;
  const pattern = new UrlPattern('/:lang(/*)');
  const { lang: langPrefix = Language.en, _: rest = '' } =
    pattern.match(currentLocation.pathname) || {};
  const currentLanguage = Language[langPrefix] || Language.en;

  const newPathname =
    currentLanguage === Language.en
      ? `${langForUrl}${
          currentLocation.pathname === '' ? '/' : currentLocation.pathname
        }`
      : `${langForUrl}/${rest}`;

  return `${newPathname}${currentLocation.search}`;
};

const exceptionalPages = [
  ...getPrivateLandlordPageRoutes().map(route => `/${route}`),
];

const getEnglishPathname = (
  alternatehrefs: { code: string; localisedLocation: string }[],
) => {
  const althrefEnglish = alternatehrefs.find(althref => althref?.code === 'en');
  return althrefEnglish?.localisedLocation || '';
};

export const getCurrentPageLanguages = (
  state: GlobalState,
  location: Location,
) => {
  let languages = getAvailableLanguages(state);

  const alternatehrefs = getAlternatehrefs(state, location.pathname);

  if (alternatehrefs.length > 0) {
    // remove this dirty hack condition after solving lang switcher problem for all pages
    if (!exceptionalPages.includes(getEnglishPathname(alternatehrefs))) {
      languages = languages.filter(lang =>
        getLocalisedLocationWithoutDefault(state, lang.code, location),
      );
    }
  }

  const localisedLocations = getLocalisedLocations(state);

  if (localisedLocations.length > 0) {
    languages = languages.filter(lang =>
      localisedLocations.some(
        localisedLocation => localisedLocation.code === lang.code,
      ),
    );
  }

  return languages.map(lang => ({
    ...lang,
    localisedUrl: getLocalisedLocation(state, lang.code, location),
  }));
};
