import { Language } from '@ha/intl';

import { GlobalState } from 'ha/types/store';

import { LinkData } from './types';
import { transformHermesData } from './utils/transformHermesData';
import { transformHermesInfo } from './utils/transformHermesInfo';

export const getError = (globalState: GlobalState) => globalState.hermes.error;

export const getData = (globalState: GlobalState) => globalState.hermes.data;

export const getInfo = (globalState: GlobalState) => globalState.hermes.info;

export const isCurrentPage = (globalState: GlobalState, pathname: string) => {
  const info = getInfo(globalState);

  if (!info) {
    return false;
  }
  const { alternatehrefs } = info.meta;

  return Object.keys(alternatehrefs).some(
    lang =>
      decodeURI(alternatehrefs[lang].toLowerCase()) ===
      decodeURI(pathname.toLowerCase()),
  );
};

export const getRoute = (globalState: GlobalState) => {
  const info = getInfo(globalState);

  return info && transformHermesInfo(info);
};

export const getRouteData = (globalState: GlobalState) => {
  const data = getData(globalState);
  const info = getInfo(globalState);

  return data && info && transformHermesData(data, info.type);
};

export const getAlternatehrefs = (
  globalState: GlobalState,
  pathname: string,
) => {
  const info = getInfo(globalState);

  if (!info || !isCurrentPage(globalState, pathname)) {
    return [];
  }

  const { alternatehrefs } = info.meta;

  return Object.keys(alternatehrefs).map(lang => ({
    code: lang,
    localisedLocation: alternatehrefs[lang],
  }));
};

export const getAlternateLanghref = (
  globalState: GlobalState,
  lang: Language,
) => {
  const info = getInfo(globalState);

  return info && info.meta.alternatehrefs[lang];
};

export const hasAlternateLanghref = (
  globalState: GlobalState,
  lang: Language,
  pathname: string,
) => {
  if (!isCurrentPage(globalState, pathname)) {
    return false;
  }

  return Boolean(getAlternateLanghref(globalState, lang));
};

type FormatUrl = (arg1: string) => string;

export function getLinkData(
  globalState: GlobalState,
  formatUrl?: FormatUrl,
): LinkData[] | undefined {
  const info = getInfo(globalState);

  if (!info) {
    return undefined;
  }

  const {
    meta: { alternatehrefs, canonical },
    availableLanguages = [],
    type,
  } = info;

  const canonicalLink = {
    rel: 'canonical' as const,
    href: formatUrl ? formatUrl(canonical) : canonical,
  };

  const xDefault: LinkData[] = [];
  if (alternatehrefs) {
    const xDefaultLink =
      // if the page is available in one language then use that as x-default
      availableLanguages.length === 1
        ? alternatehrefs[availableLanguages[0]]
        : alternatehrefs[Language.en];

    if (xDefaultLink) {
      xDefault.push({
        rel: 'alternate' as const,
        hrefLang: 'x-default',
        href: formatUrl ? formatUrl(xDefaultLink) : xDefaultLink,
      });
    }
  }

  const alternateLinks: LinkData[] = (
    ['Country', 'Cities'].includes(type)
      ? Object.keys(alternatehrefs)
      : availableLanguages
  ).map(lang => ({
    rel: 'alternate' as const,
    hrefLang: lang,
    href: formatUrl ? formatUrl(alternatehrefs[lang]) : alternatehrefs[lang],
  }));

  return [
    canonicalLink,
    ...xDefault,
    ...(alternateLinks.length > 1 ? alternateLinks : []),
  ];
}
